import { navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setEGiftCard } from '../../../redux/slices/egiftCardSlice';

import { TextArea, Input } from '@components/common';
import Button from '@components/common/Button';
import { Layout } from '@components/layout';
import { Style } from '@features/e-cart/style';

const EGiftMessagePage: FC = () => {
  const eGiftCard = useAppSelector((state) => state.eGiftCardReducer.eGiftCard);
  const dispatch = useAppDispatch();

  const [receiver, setReceiver] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [sender, setSender] = useState<string>('');

  useEffect(() => {
    if (eGiftCard) {
      setReceiver(eGiftCard.receiver);
      setReceiver(eGiftCard.message);
      setReceiver(eGiftCard.sender);
    }
  }, [eGiftCard]);
  const intl = useIntl();

  return (
    <Layout>
      <Style.Wrap>
        <Style.Container>
          <h5 className="text-center my-3 mx-auto" style={{ maxWidth: '300px' }}>
            <FormattedMessage id="e-gift.hint2" />
          </h5>
          <form>
            <div className="form-group">
              <label className="font-weight-400" htmlFor="receiver">
                <FormattedMessage id="e-gift.receiver" />
              </label>
              <Input
                className="py-2"
                name="receiver"
                required={true}
                value={receiver}
                onChange={(value: string) => setReceiver(value)}
                placeholder="ex:虎杖悠仁"
                errorMessage={intl.formatMessage(
                  { id: 'format.require' },
                  { e: intl.formatMessage({ id: 'e-gift.receiver' }) }
                )}
              />
            </div>
            <div className="form-group">
              <label className="font-weight-400" htmlFor="message">
                <FormattedMessage id="e-gift.message" />
              </label>
              <TextArea
                className="py-2"
                name="message"
                value={message}
                placeholder="ex:お誕生日おめでとうございます。"
                onChange={(value: string) => setMessage(value)}
              />
            </div>
            <div className="form-group">
              <label className="font-weight-400" htmlFor="sender">
                <FormattedMessage id="e-gift.sender" />
              </label>
              <Input
                className="py-2"
                name="sender"
                required={true}
                value={sender}
                onChange={(value: string) => setSender(value)}
                placeholder="ex:五条悟"
                errorMessage={intl.formatMessage(
                  { id: 'format.require' },
                  { e: intl.formatMessage({ id: 'e-gift.sender' }) }
                )}
              />
            </div>
            <div className="form-group">
              <Button
                className="my-3"
                disabled={!(receiver && sender && message)}
                width="250px"
                onClick={() => {
                  dispatch(
                    setEGiftCard({
                      eGiftCard: {
                        receiver,
                        message,
                        sender,
                        templateIndex: 0,
                      },
                    })
                  );
                  navigate('/e-gift/template-select');
                }}
              >
                <FormattedMessage id="order.next" />
              </Button>
              <Button
                className="my-3 secondary"
                width="180px"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FormattedMessage id="back.page" />
              </Button>
            </div>
          </form>
        </Style.Container>
      </Style.Wrap>
    </Layout>
  );
};

export default React.memo(EGiftMessagePage);
